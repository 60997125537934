// 引入axios
import axios from 'axios'
import { ElMessageBox  } from 'element-plus'
//引入localstorage
import Cookies from "js-cookie";
import localstorage from '@/utils/localStorage'

// 创建axios实例
const instance = axios.create({
  baseURL: 'https://xmthz.cn/api/v1/pub'
})

// 请求拦截器
instance.interceptors.request.use(config => {
  // 在发送请求之前做些什么
   const token = localstorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  return config
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(response => {
	if (response.data.code === 401) {
		ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
			localstorage.removeItem('userName');
			localstorage.removeItem('token');
			localstorage.removeItem('roleName');
			location.href = '/login';
		}).catch(() => {
			localstorage.removeItem('userName');
			localstorage.removeItem('token');
			localstorage.removeItem('roleName');
			location.href = '/';
		});
	  return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
	}
  // 对响应数据做些什么
  return response.data
}, error => {
  // 对响应错误做些什么
  return Promise.reject(error)
})

export default instance

/* 
 
 // 设置基础URL
 axios.defaults.baseURL = 'http://api.example.com'
 
 // 设置请求超时时间
 axios.defaults.timeout = 5000
 
 // 在请求发送之前做一些处理，例如添加token等
 axios.interceptors.request.use(
   config => {
     // 在发送请求之前做些什么
     const token = localStorage.getItem('token')
     if (token) {
       config.headers['Authorization'] = 'Bearer ' + token
     }
     return config
   },
   error => {
     // 处理请求错误
     return Promise.reject(error)
   }
 )
 
 // 在请求返回之后做一些处理，例如判断请求是否成功等
 axios.interceptors.response.use(
   response => {
     // 对响应数据做些什么
     if (response.data.code === 200) {
       return response.data
     } else {
       return Promise.reject(response.data.message)
     }
   },
   error => {
     // 处理响应错误
     return Promise.reject(error)
   }
 )
 
 // 在Vue中将axios绑定到原型上，方便在组件中使用
 Vue.prototype.$axios = axios
 
 
 */

