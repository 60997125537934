<template>
    
    <footer>
		<!--<div style=" height: 276px; width: 100%;" :style="'background-image:url('+ footBg +');'">
			 <div style="width: 62%; padding-left: 19%; ">
				<div style="padding: 0 100px;">
					<li style="float: left; list-style: none; color: #ffffff; width: 50%; padding: 50px 0">
						<div style="font-size: 27px; font-weight: bold; font-style: normal;">参展咨询</div>
						<div style="width:40%; margin-top: 40px; float: left;">
							<div style="font-size: 18px; font-weight:bold; font-style:normal;">黄友庚<br>
							Michael Huang</div>
							<div style="font-size: 18px; font-weight:400;letter-spacing: 1px; font-style:normal; margin-top:5px;">市场联络部部长</div>
						</div>
						<div style="width:60%; margin-top: 20px; float: left;">
							<div style="font-size: 18px; height: 40px; font-weight:normal; font-style:normal; width: 100%;">
								<div style="float: left;"><img src="../assets/images/tel.svg" style="width: 20px; margin-top: 2px;"/></div>
								<div style="margin-left: 30px;">+86-21-54565366-808</div>
							</div>
							<div style="font-size: 18px; height: 40px;font-weight:normal; font-style:normal; width: 100%;">
								<div style="float: left;"><img src="../assets/images/phone.svg" style="width: 20px; margin-top: 2px;"/></div>
								<div style="margin-left: 30px;">+86-189-1719-1744</div>
							</div>
							<div style="font-size: 18px; height: 40px;font-weight:normal; font-style:normal; width: 100%;">
								<div style="float: left;"><img src="../assets/images/email.png" style="width: 20px; margin-top: 2px;"/></div>
								<div style="margin-left: 30px;">huangyg@cicmag.com</div>
							</div>
						</div>
					</li>
					<li style="width: 0.5%; float: left; height: 225px; list-style: none; padding: 20px 0; text-align: center;">
						<div style="width: 2px; height: 235px; background-color: #ffffff;"></div>
					</li>
					<li style="float: left; list-style: none; color: #ffffff; width: 49%; padding: 50px 0">
						<div style="font-size: 27px; font-weight: bold; font-style: normal; margin-left: 60px;">参会咨询</div>
						<div style="margin-left: 60px;">
							<div style="width:40%; margin-top: 40px; float: left;">
								<div style="font-size: 18px; font-weight:bold; font-style:normal;">张柳萍<br>Liuping Zhang</div>
								<div style="font-size: 18px; font-weight:400;letter-spacing: 1px; font-style:normal; margin-top:5px;">会议邀请</div>
							</div>
							<div style="width:54%; margin-left: 6%; margin-top: 20px; float: left;">
								<div style="font-size: 18px; height: 40px; font-weight:normal; font-style:normal; width: 100%;">
									<div style="float: left;"><img src="../assets/images/tel.svg" style="width: 20px; margin-top: 2px;"/></div>
									<div style="margin-left: 30px;">+86-21-54565366-818</div>
								</div>
								<div style="font-size: 18px; height: 40px;font-weight:normal; font-style:normal; width: 100%;">
									<div style="float: left;"><img src="../assets/images/phone.svg" style="width: 20px; margin-top: 2px;"/></div>
									<div style="margin-left: 30px;">+86-181-1612-6096</div>
								</div>
								<div style="font-size: 18px; height: 40px;font-weight:normal; font-style:normal; width: 100%;">
									<div style="float: left;"><img src="../assets/images/email.png" style="width: 20px; margin-top: 2px;"/></div>
									<div style="margin-left: 30px;">zhanglp@cicmag.com</div>
								</div>
							</div>
						</div>
						
					</li>
				</div>
			</div> 
		</div>-->
		<div style="width: 100%; height: 375px; background-color: black;">
			<div style="width: 62%; padding: 60px 19%; color: #ffffff;">
				<div style="width: 45%; float: left;">
					<li style="list-style: none;"><div style="font-size: 1.2rem; height: 40px; font-weight: bold;">联络我们</div></li>
					<li style="list-style: none;"><div style="font-size: 0.9rem;height: 30px;" class="link-1"><a class="demo">{{ config.company }}</a></div></li>
					<li style="list-style: none;"><div style="font-size: 0.9rem;height: 30px;" class="link-1"><a class="demo">{{ config.address }}</a></div></li>
					<li style="list-style: none;"><div style="font-size: 0.9rem;height: 30px;" class="link-1"><a class="demo">Tel: {{ config.phone }}</a></div></li>
					<li style="list-style: none;"><div style="font-size: 0.9rem;height: 30px;" class="link-1"><a class="demo">Mobile: {{ config.mobile }}</a></div></li>
					<li style="list-style: none;"><div style="font-size: 0.9rem;height: 30px;" class="link-1"><a class="demo">Email: {{ config.email }}</a></div></li>
				</div>
				<div style="width: 24%;float: left;">
					<li style="list-style: none;"><div style="font-size: 1rem;height: 40px; cursor: pointer;" @click="open('http://xmthz.cn')" class="link-1"><a class="demo">首页</a></div></li>
					<li style="list-style: none;"><div style="font-size: 1rem;height: 40px; cursor: pointer;" @click="open('http://icdia.xmthz.cn')" class="link-1"><a class="demo">ICDIA大会</a></div></li>
					<li style="list-style: none;"><div style="font-size: 1rem;height: 40px; cursor: pointer;" @click="open('http://iccad.xmthz.cn')" class="link-1"><a class="demo">ICCAD-Expo</a></div></li>
					<li style="list-style: none;"><div style="font-size: 1rem;height: 40px; cursor: pointer;" @click="open('http://aeif.xmthz.cn')" class="link-1"><a class="demo">AEIF大会</a></div></li>
				</div>
				<div style="width: 31%;float: left;">
					<div style="width:30%; text-align: center;float: left; padding-top:100px;">
						<img src="../assets/images/ICCAD.png"><br>
						<span style="font-size: 10px;">ICCAD微信公众号</span>
					</div>
					<div style="width: 5%; height: 200px; float: left;"></div>
					<div style="width:30%; text-align: center;float: left; padding-top:100px;">
						<img src="../assets/images/CIC.png"><br>
						<span style="font-size: 10px;">CIC微信公众号</span>
					</div>
					<div style="width: 5%; height: 200px; float: left;"></div>
					<div style="width:30%; text-align: center;float: left; padding-top:100px;">
						<img src="../assets/images/ICDIA.png"><br>
						<span style="font-size: 10px;">ICDIA微信公众号</span>
					</div>
				</div>
				<div style="width: 50%; font-size: 14px; font-weight: normal; float: left; color: #8c8c8c; margin-top: 30px;">Copyright xmthz.cn. All rights reserved.</div>
				<div style="width: 50%; font-size: 14px; font-weight: normal; float: left; color: #8c8c8c; text-align: right; margin-top: 30px;">沪ICP备 2023008635号-1</div>
			</div>
		</div>
    </footer>


</template>

<script>
import footBg from '@/assets/images/foot.jpg';
import request from '@/api/axios'
export default{
	components: {
		footBg,
	},
    data(){
        return{
			config: {},
			footBg: footBg
        }
    },
	mounted() {
		this.getData()
	},
	methods: {
		open(url) {
			window.location.href= url
		},
		getData() {
			request({url: '/icdia/hzIccadConfig/list', method: 'get'}).then(res => {
				if (res.code == 0) {
					this.config = res.data.list[0]
				}
			});
		}
	}
}


</script>

<style scoped>
.wrapper {
  display: table;
  height: 100%; width: 100%;
}

.stage {
  display: table-cell;
  vertical-align: middle;
}

.tabled, .middled {
  text-align: center;
  margin: 0 auto;
}

.thin { font-weight: 300; }
.thick { font-weight: 900; }

a.demo {
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  position: relative;
  display: block;
}

p.desc {
  font-weight: 100;
  font-size: 80%;
  margin: 1px;
}

[class^="link-"] {
  display: inline-block;
}

/* linkone */
.link-1 a.demo:before, .link-1 a.demo:after {
  content: '';
  border-bottom: solid 1px white;
  position: absolute;
  bottom: 0;
  width: 0;
}

.link-1 a.demo:before { left: 0; }
.link-1 a.demo:after { right: 0; }

.link-1 a.demo:hover:before, .link-1 a.demo:hover:after {
  width: 50%;
}

.link-1 a.demo:before, .link-1 a.demo:after {
  -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
}
@-webkit-keyframes heartbeat {
  0% { -webkit-transform: scale(1); }
  10% { -webkit-transform: scale(1.1); }
  20% { -webkit-transform: scale(1); }
  30% { -webkit-transform: scale(1.1); }
  40% { -webkit-transform: scale(1); }
}

@-webkit-keyframes heartbeat-x {
  0% { -webkit-transform: scaleX(0); }
  10% { -webkit-transform: scaleX(1); }
  20% { -webkit-transform: scaleX(0); }
  30% { -webkit-transform: scaleX(1); }
  40% { -webkit-transform: scaleX(0); }
}

@keyframes heartbeat {
  0% { transform: scale(1); }
  10% { transform: scale(1.1); }
  20% { transform: scale(1); }
  30% { transform: scale(1.1); }
  40% { transform: scale(1); }
}

@keyframes heartbeat-x {
  0% { transform: scaleX(0); }
  10% { transform: scaleX(1); }
  20% { transform: scaleX(0); }
  30% { transform: scaleX(1); }
  40% { transform: scaleX(0); }
}

</style>